import * as React from "react";
import Layout from "../components/Layout/Layout";
import parse from "html-react-parser";
import { Link } from "gatsby";
import ServicePackageItem from "../components/index/ServicePackageItem";
import BreadCrumbs from "../BreadCrumbs";
import { injectIntl } from "gatsby-plugin-intl";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_GROUP } from "../query/getAnalysis";
import { moneyFormatFromNumber } from "../utils/formatter";

//@ts-ignore
const complexServicePackages = ({ intl, pageContext }) => {
  const { data, loading, error } = useQuery(GET_ANALYSIS_GROUP, {
    variables: {
      locale: intl.locale,
      pagination: {
        pageSize: 20,
      },
    },
  });

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  return (
    <Layout>
      <div className="grid xs:justify-center  sm:pl-0">
        <div className="flex flex-col grid-rows-2 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
          <div className="ml-[0.2rem] mt-[2.5rem] mb-[2rem] ">
            <BreadCrumbs
              pageContext={pageContext}
              title="ServicePackages.ServicePackagesHeader"
            />
          </div>
          <div className="grid items-start h-auto justify-between mb-[2rem]  ">
            <h2 className="text-[2rem] font-semibold leading-9 ">
              {parse(
                intl.formatMessage({
                  id: "ServicePackages.ServicePackagesHeader",
                })
              )}
            </h2>
          </div>

          <div className="lg:mt-[0rem] grid lg:grid-cols-3 sm:grid-cols-2  items-start   lg:gap-9 sm:gap-5 xs:gap-4 cursor-pointer">
            {data.analysisGroups.data.map((analysisGroup: any) => (
              <Link
                to={`/complex-service-packages-detail/?id=${analysisGroup.id}`}
              >
                <ServicePackageItem
                  intl={intl}
                  title={analysisGroup.attributes.title}
                  price={moneyFormatFromNumber(analysisGroup.attributes.price)}
                  img={analysisGroup.attributes.icon?.data?.attributes?.url}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(complexServicePackages);
